import React from "react";

import "../components/bootstrap.min.css";
import "../components/vendor/tether/tether.min.css";
import "../components/vendor/bootstrap/css/bootstrap.min.css";
import "../components/css/fonts/express-icons.css";
import "../components/css/card.css";

import "../components/vendor/font-awesome/css/font-awesome.min.css";
import "../components/vendor/bootstrap/css/glyphicon.css";
import "../components/vendor/cubeportfolio/css/cubeportfolio.min.css";

//import '../components/vendor/ion-icons/css/ionicons.min.css',
import "../components/vendor/owl-carousel/owl.theme.css";
import "../components/vendor/owl-carousel/owl.carousel.css";
import "../components/vendor/magnific-popup/magnific-popup.css";
import "../components/vendor/lite-tooltip/css/litetooltip.css";
import "../components/vendor/PricingTablesInspiration/css/component.css";
import "../components/vendor/PricingTablesInspiration/css/icons.css";
import "../components/vendor/PricingTablesInspiration/css/demo.css";
//Theme CSS
import "../components/css/main.css";
import "../components/css/main-shortcodes.css";
import "../components/css/header.css";
import "../components/css/form-element.css";
import "../components/css/animation.css";
import "../components/css/bg-stylish.css";
import "../components/css/blog.css";
import "../components/css/streamline-icon.css";
import "../components/css/responsive.css";
import "../components/css/utilities.css";
import "../components/css/portfolio.css";
import "../components/css/font-icons.css";
import "../components/css/skins/default.css";

//Theme Custom CSS
import "../components/css/custom.css";

import Footer from "../components/footer";
import Header from "../components/header";

const IndexPage = () => (
  <div class="wrapper">
    <Header />
    <div class="page">
      <section class="section-primary pb-30 " style={{backgroundColor: "grey"}}>
        <div class="container" style={{ marginTop: 0 }}>
          <div class="row text-white text-center">
            <div
              class="col-md-12"
              style={{ paddingLeft: 50, paddingRight: 50 }}
            >
              <h2 class="section-title fs-40 pt-10 mb-10 fw-5">
                Earn money with pubwoof
              </h2>
              <p class="section-sub-title o-9 text-white">
                Our creators earn as much as 70% of their total sales. Moreover
                our creators have multiple income streams that ensures that you
                make sales even when you are sleeping. Our weekly payments and
                integrations with majority of local payment gateways ensures
                that your get timely payments .
              </p>
            </div>

            <div class="col-md-10 offset-md-1">
              <div class="clearfix mt-40">
                <a
                  href={`${process.env.GATSBY_API_URL}/creators`}
                  class="btn btn-primary item-shadow-5x btn-rounded"
                >
                   Start earning
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div class="page-inner p-none no-border">
        <section
          class="section-big main-color right-top-br bg-ps-left"
          style={{
            backgroundImage: "url(/img/banner/s2.jpg)"
          }}
        >
          <div class="container ">
            <div class="row">
              <div class="col-md-4"></div>

              <div class="col-sm-5 offset-md-3">
                <h1 class="section-title">70% profit share</h1>
                <div class="exp-separator">
                  <div class="exp-separator-inner"></div>
                </div>

                <p>
                  We pay 70% of the total revenue each month to our creators.
                  Each creator receives 70% percentage of the template sales and
                  tasks performed. It’s that simple.
                </p>

                <a
                  href={`${process.env.GATSBY_API_URL}/creators`}
                  class="btn btn-primary btn-rounded"
                >
                  Register
                </a>
              </div>
            </div>
          </div>
        </section>

        <section class="section-primary main-color">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <section class="section-primary main-color b-bordered">
                  <div class="container">
                    <div class="row">
                      <div class="col-md-10 offset-md-1">
                        <div class="tab-wrap line-tab">
                          <div class="tab-content">
                            <div
                              class="tab-pane active"
                              id="Default"
                              role="tabpanel"
                            >
                              <div class="testimonial-one text-center">
                                <blockquote class="pullquote">
                                  <p class="fs-20">
                                    Become a creator on pubwoof has been a dream come true. I decided to leave 
                                    my Jobs so that I could fully focus on earning a living from the platform.
                                  </p>

                                  <footer class="blockquote-footer fs-30">
                                    Moses.
                                    <cite title="Source Title">
                                      a pubwoof creator
                                    </cite>
                                  </footer>

                                  <div class="testimonial-image">
                                    <img
                                      src="img/avatars/person.jpeg"
                                      width="50"
                                    />
                                  </div>
                                </blockquote>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <Footer />
  </div>
);

export default IndexPage;
